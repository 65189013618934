export const createGenerationJob = /* GraphQL */ `
  mutation CreateGenerationJob($generationJob: GenerationJobInput!) {
    createGenerationJob(generationJob: $generationJob) {
      uuid
      type
      params
      status
      note
      uploadImageURLs
      user {
        sub
        name
        picture
      }
      createdAt
      updatedAt
    }
  }
`;

export const startGeneration = /* GraphQL */ `
  mutation StartGeneration($id: CollectionID!) {
    startGeneration(id: $id) {
      uuid
      type
      params
      status
      note
      user {
        sub
        name
        picture
      }
      createdAt
      updatedAt
    }
  }
`;

export const cancelGeneration = /* GraphQL */ `
  mutation CancelGeneration($id: CollectionID!) {
    cancelGeneration(id: $id)
  }
`;

export const updateUserCommunications = /* GraphQL */ `
  mutation UpdateUserCommunications(
    $updateUserCommunications: UpdateUserCommunicationsInput!
  ) {
    updateUserCommunications(
      updateUserCommunications: $updateUserCommunications
    ) {
      sub
      productUpdateEmails
    }
  }
`;

export const deleteImages = /* GraphQL */ `
  mutation DeleteImages($ids: [CollectionID!]!) {
    deleteImages(ids: $ids)
  }
`;
