/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type GenerationJobInput = {
  type: JobType,
  params: string,
  initImageURLs?: Array< string | null > | null,
  userSub?: string | null,
};

export enum JobType {
  TXT2IMG = "TXT2IMG",
  IMG2IMG = "IMG2IMG",
  UPSCALE = "UPSCALE",
  DESCRIBE = "DESCRIBE",
  TXT2ANIM = "TXT2ANIM",
}


export type GenerationJob = {
  __typename: "GenerationJob",
  uuid: string,
  type: JobType,
  params: string,
  status?: JobStatus | null,
  note?: string | null,
  initImageURLs?: Array< string | null > | null,
  uploadImageURLs?: Array< string | null > | null,
  user: User,
  createdAt: string,
  updatedAt: string,
};

export type Job = {
  __typename: "Job",
  type: JobType,
  params: string,
  status?: JobStatus | null,
  note?: string | null,
  uploadImageURLs?: Array< string | null > | null,
};

export type Collection = {
  __typename: "Collection",
  uuid: string,
  user: User,
  createdAt: string,
  updatedAt: string,
};

export type Image = {
  __typename: "Image",
  uuid: string,
  jobId?: string | null,
  jobType?: JobType | null,
  url: string,
  fullSizeUrl?: string | null,
  params: string,
  user: User,
  createdAt: string,
  updatedAt: string,
};

export type Node = {
  __typename: "Node",
  uuid: string,
  createdAt: string,
};

export type User = {
  __typename: "User",
  sub: string,
  name?: string | null,
  picture?: string | null,
  plan?: string | null,
  subscriptionCurrentPeriodEnd?: string | null,
  email?: string | null,
  images?: ImageConnection | null,
  imageCount?: number | null,
  expiringCredits?: number | null,
  nonExpiringCredits?: number | null,
  productUpdateEmails?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type ImageConnection = {
  __typename: "ImageConnection",
  totalCount: number,
  edges:  Array<ImageEdge | null >,
  pageInfo: PageInfo,
};

export type Connection = {
  __typename: "Connection",
  totalCount: number,
  edges:  Array<Edge | null >,
  pageInfo: PageInfo,
};

export type ImageEdge = {
  __typename: "ImageEdge",
  node: Image,
  cursor: string,
};

export type Edge = {
  __typename: "Edge",
  node: Node,
  cursor: string,
};

export type PageInfo = {
  __typename: "PageInfo",
  hasPreviousPage: boolean,
  hasNextPage: boolean,
  startCursor?: string | null,
  endCursor?: string | null,
};

export enum JobStatus {
  CREATED = "CREATED",
  UPLOADING = "UPLOADING",
  UPLOAD_FAILED = "UPLOAD_FAILED",
  IN_QUEUE = "IN_QUEUE",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETE = "COMPLETE",
  ERROR = "ERROR",
  CANCELED = "CANCELED",
}


export type CollectionID = {
  uuid: string,
  createdAt: string,
};

export type UpdateUserCommunicationsInput = {
  sub: string,
  productUpdateEmails?: boolean | null,
};

export type Checkpoint = {
  __typename: "Checkpoint",
  id: string,
  planType: PlanType,
  displayName: string,
  description?: string | null,
  thumbnail?: string | null,
  authorName?: string | null,
  authorLink?: string | null,
  preference?: CheckpointPreference | null,
  createdAt: string,
  updatedAt: string,
};

export type Model = {
  __typename: "Model",
  id: string,
  displayName: string,
  description?: string | null,
  authorName?: string | null,
  authorLink?: string | null,
  thumbnail?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type Embedding = {
  __typename: "Embedding",
  id: string,
  modelBase: string,
  type: string,
  tag: string,
  thumbnail?: string | null,
  displayName: string,
  description?: string | null,
  authorName?: string | null,
  authorLink?: string | null,
  createdAt: string,
  updatedAt: string,
};

export enum PlanType {
  FREE = "FREE",
  BASIC = "BASIC",
  PRO = "PRO",
}


export type CheckpointPreference = {
  __typename: "CheckpointPreference",
  prompt?: string | null,
  negPrompt?: string | null,
  negStyle?: string | null,
  width?: number | null,
  height?: number | null,
  guidance?: number | null,
  quality?: number | null,
  scheduler?: string | null,
};

export type CreateGenerationJobMutationVariables = {
  generationJob: GenerationJobInput,
};

export type CreateGenerationJobMutation = {
  createGenerationJob?:  {
    __typename: "GenerationJob",
    uuid: string,
    type: JobType,
    params: string,
    status?: JobStatus | null,
    note?: string | null,
    initImageURLs?: Array< string | null > | null,
    uploadImageURLs?: Array< string | null > | null,
    user:  {
      __typename: "User",
      sub: string,
      name?: string | null,
      picture?: string | null,
      plan?: string | null,
      subscriptionCurrentPeriodEnd?: string | null,
      email?: string | null,
      imageCount?: number | null,
      expiringCredits?: number | null,
      nonExpiringCredits?: number | null,
      productUpdateEmails?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type StartGenerationMutationVariables = {
  id: CollectionID,
};

export type StartGenerationMutation = {
  startGeneration?:  {
    __typename: "GenerationJob",
    uuid: string,
    type: JobType,
    params: string,
    status?: JobStatus | null,
    note?: string | null,
    initImageURLs?: Array< string | null > | null,
    uploadImageURLs?: Array< string | null > | null,
    user:  {
      __typename: "User",
      sub: string,
      name?: string | null,
      picture?: string | null,
      plan?: string | null,
      subscriptionCurrentPeriodEnd?: string | null,
      email?: string | null,
      imageCount?: number | null,
      expiringCredits?: number | null,
      nonExpiringCredits?: number | null,
      productUpdateEmails?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CancelGenerationMutationVariables = {
  id: CollectionID,
};

export type CancelGenerationMutation = {
  cancelGeneration?: string | null,
};

export type UpdateUserCommunicationsMutationVariables = {
  updateUserCommunications: UpdateUserCommunicationsInput,
};

export type UpdateUserCommunicationsMutation = {
  updateUserCommunications?:  {
    __typename: "User",
    sub: string,
    name?: string | null,
    picture?: string | null,
    plan?: string | null,
    subscriptionCurrentPeriodEnd?: string | null,
    email?: string | null,
    images?:  {
      __typename: "ImageConnection",
      totalCount: number,
    } | null,
    imageCount?: number | null,
    expiringCredits?: number | null,
    nonExpiringCredits?: number | null,
    productUpdateEmails?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteImagesMutationVariables = {
  ids: Array< CollectionID >,
};

export type DeleteImagesMutation = {
  deleteImages?: string | null,
};

export type GetGenerationJobByIdQueryVariables = {
  id: CollectionID,
};

export type GetGenerationJobByIdQuery = {
  getGenerationJobById?:  {
    __typename: "GenerationJob",
    uuid: string,
    type: JobType,
    params: string,
    status?: JobStatus | null,
    note?: string | null,
    initImageURLs?: Array< string | null > | null,
    uploadImageURLs?: Array< string | null > | null,
    user:  {
      __typename: "User",
      sub: string,
      name?: string | null,
      picture?: string | null,
      plan?: string | null,
      subscriptionCurrentPeriodEnd?: string | null,
      email?: string | null,
      imageCount?: number | null,
      expiringCredits?: number | null,
      nonExpiringCredits?: number | null,
      productUpdateEmails?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetUserByIdQueryVariables = {
  userSub: string,
};

export type GetUserByIdQuery = {
  getUserById?:  {
    __typename: "User",
    sub: string,
    name?: string | null,
    picture?: string | null,
    plan?: string | null,
    subscriptionCurrentPeriodEnd?: string | null,
    email?: string | null,
    images?:  {
      __typename: "ImageConnection",
      totalCount: number,
    } | null,
    imageCount?: number | null,
    expiringCredits?: number | null,
    nonExpiringCredits?: number | null,
    productUpdateEmails?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCheckpointsQuery = {
  listCheckpoints?:  Array< {
    __typename: "Checkpoint",
    id: string,
    planType: PlanType,
    displayName: string,
    description?: string | null,
    thumbnail?: string | null,
    authorName?: string | null,
    authorLink?: string | null,
    preference?:  {
      __typename: "CheckpointPreference",
      prompt?: string | null,
      negPrompt?: string | null,
      negStyle?: string | null,
      width?: number | null,
      height?: number | null,
      guidance?: number | null,
      quality?: number | null,
      scheduler?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } > | null,
};

export type ListEmbeddingsQuery = {
  listEmbeddings?:  Array< {
    __typename: "Embedding",
    id: string,
    modelBase: string,
    type: string,
    tag: string,
    thumbnail?: string | null,
    displayName: string,
    description?: string | null,
    authorName?: string | null,
    authorLink?: string | null,
    createdAt: string,
    updatedAt: string,
  } > | null,
};

export type SubscribeToUpdateGenerationJobSubscriptionVariables = {
  uuid: string,
};

export type SubscribeToUpdateGenerationJobSubscription = {
  subscribeToUpdateGenerationJob?:  {
    __typename: "GenerationJob",
    uuid: string,
    type: JobType,
    params: string,
    status?: JobStatus | null,
    note?: string | null,
    initImageURLs?: Array< string | null > | null,
    uploadImageURLs?: Array< string | null > | null,
    user:  {
      __typename: "User",
      sub: string,
      name?: string | null,
      picture?: string | null,
      plan?: string | null,
      subscriptionCurrentPeriodEnd?: string | null,
      email?: string | null,
      imageCount?: number | null,
      expiringCredits?: number | null,
      nonExpiringCredits?: number | null,
      productUpdateEmails?: boolean | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};
