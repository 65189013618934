export const getGenerationJobById = /* GraphQL */ `
  query GetGenerationJobById($id: CollectionID!) {
    getGenerationJobById(id: $id) {
      uuid
      type
      params
      status
      note
      initImageURLs
      uploadImageURLs
      user {
        sub
        name
      }
      createdAt
      updatedAt
    }
  }
`;

export const getUserById = /* GraphQL */ `
  query GetUserById($userSub: String!) {
    getUserById(userSub: $userSub) {
      sub
      name
      picture
      plan
      subscriptionCurrentPeriodEnd
      imageCount
      expiringCredits
      nonExpiringCredits
      productUpdateEmails
      createdAt
      updatedAt
    }
  }
`;

export const getUserByIdWithImages = /* GraphQL */ `
  query GetUserById($userSub: String!, $first: Int, $after: String) {
    getUserById(userSub: $userSub) {
      sub
      name
      picture
      plan
      subscriptionCurrentPeriodEnd
      imageCount
      expiringCredits
      nonExpiringCredits
      productUpdateEmails
      images(first: $first, after: $after) {
        totalCount
        edges {
          node {
            uuid
            jobId
            jobType
            url
            fullSizeUrl
            params
            createdAt
            updatedAt
          }
          cursor
        }
        pageInfo {
          hasNextPage
          startCursor
          endCursor
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const listCheckpoints = /* GraphQL */ `
  query ListCheckpoints {
    listCheckpoints {
      id
      planType
      displayName
      description
      thumbnail
      authorName
      authorLink
      preference {
        prompt
        negPrompt
        negStyle
        width
        height
        guidance
        quality
        scheduler
      }
      createdAt
      updatedAt
    }
  }
`;
export const listEmbeddings = /* GraphQL */ `
  query ListEmbeddings {
    listEmbeddings {
      id
      modelBase
      type
      tag
      thumbnail
      displayName
      description
      authorName
      authorLink
      createdAt
      updatedAt
    }
  }
`;
