import { API } from 'aws-amplify';
import { GraphQLSubscription } from '@aws-amplify/api';
import {
  getGenerationJobById,
  getUserById,
  getUserByIdWithImages,
  listCheckpoints,
  listEmbeddings,
} from '../customGraphql/queries';
import {
  createGenerationJob,
  startGeneration,
  cancelGeneration,
  updateUserCommunications,
  deleteImages,
} from '../customGraphql/mutations';
import { subscribeToUpdateGenerationJob } from '../customGraphql/subscriptions';
import { AuthMode, getCurrentAuthMode } from './auth-helper';
import {
  CollectionID,
  GenerationJob,
  User,
  GenerationJobInput,
  Embedding,
  Checkpoint,
} from '../API';

const graphqlHelper = (query: string, variables: any, authMode?: AuthMode) => {
  return new Promise(async (resolve, reject) => {
    const currentAuthMode = await getCurrentAuthMode();
    console.log({ authMode: currentAuthMode });
    try {
      const result = await API.graphql({
        query,
        variables,
        authMode: authMode ?? currentAuthMode,
      });
      resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};

const subscriptionHelper = (
  query: string,
  variables: any,
  onRecieve: any,
  onError?: any
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const authMode = await getCurrentAuthMode();
      const subscription = await API.graphql<GraphQLSubscription<any>>({
        query,
        variables,
        authMode: authMode,
      });
      const res = (subscription as any).subscribe({
        next: ({ provider, value }) => {
          console.log({ provider, value });
          onRecieve(value);
        },
        error: (error) => {
          console.warn(error);
          if (onError != null) {
            onError(error);
          }
        },
      });

      console.log(`Subscription value: ${JSON.stringify(res)}`);

      resolve(res);
    } catch (e) {
      reject(e);
      throw new Error(`Could not subscribe: ${e}`);
    }
  });
};

export const listCheckpointsHelper = async () => {
  const result: any = await graphqlHelper(listCheckpoints, null);
  return result.data.listCheckpoints as Checkpoint[];
};

export const listEmbeddingsHelper = async () => {
  const result: any = await graphqlHelper(listEmbeddings, null);
  return result.data.listEmbeddings as Embedding[];
};

export const deleteImageHelper = async (imgID: CollectionID) => {
  console.log(`given id: ${JSON.stringify(imgID)}`);
  const result: any = await graphqlHelper(deleteImages, {
    ids: [imgID],
  });
  return result.data.deleteImages as string;
};

export const deleteImagesHelper = async (imgIDs: CollectionID[]) => {
  console.log(`given ids: ${JSON.stringify(imgIDs)}`);

  const result: any = await graphqlHelper(deleteImages, {
    ids: imgIDs,
  });
  return result.data.deleteImages as string;
};

export const updateUserCommunicationsHelper = async (
  userSub: string,
  productUpdateEmails: boolean
) => {
  console.log(`productUpdateEmails: ${JSON.stringify(productUpdateEmails)}`);

  const result: any = await graphqlHelper(updateUserCommunications, {
    updateUserCommunications: { sub: userSub, productUpdateEmails },
  });
  return result.data.updateUserCommunications as User;
};

export const getUserByIdHelper = async (userSub): Promise<any> => {
  return graphqlHelper(getUserById, { userSub });
};

export const getUserByIdWithImagesHelper = async (
  userSub: string,
  first: number,
  after?: string
) => {
  const result: any = await graphqlHelper(getUserByIdWithImages, {
    userSub,
    first,
    after,
  });

  return result.data.getUserById as User;
};

export const createGenerationJobHelper = async (
  jobData: GenerationJobInput
) => {
  const genJob = {
    ...jobData,
    params:
      typeof jobData.params === 'string'
        ? jobData.params
        : JSON.stringify(jobData.params),
  }; // TODO
  const result: any = await graphqlHelper(createGenerationJob, {
    generationJob: genJob,
  });
  return result.data.createGenerationJob as GenerationJob;
};

export const startGenerationHelper = async (jobID: CollectionID) => {
  const result: any = await graphqlHelper(startGeneration, { id: jobID });
  return result.data.startGeneration as GenerationJob;
};

export const cancelGenerationHelper = async (jobID: CollectionID) => {
  const result: any = await graphqlHelper(cancelGeneration, { id: jobID });
  return result.data.cancelGeneration as string;
};

export const getGenerationJobByIdHelper = async (jobID: CollectionID) => {
  const result: any = await graphqlHelper(getGenerationJobById, { id: jobID });
  return result.data.getGenerationJobById as GenerationJob;
};

export const subscribeToUpdateGenerationJobHelper = async (
  uuid: string,
  onRecieve: any,
  onError?: any
) => {
  return await subscriptionHelper(
    subscribeToUpdateGenerationJob,
    { uuid },
    onRecieve,
    onError
  );
};
